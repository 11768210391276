import {
  LocalizationWords,
  Offering,
  OfferingReview,
  DocumentationCategory,
  OfferingReviewStatus,
  OfferingStatus,
  RegionalNameInformation,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Link } from '@amzn/awsui-components-react';
import { OfferingStatusIndicator } from './f2/OfferingStatusIndicator';
import { OfferingReviewStatusIndicator } from './f2/OfferingReviewStatusIndicator';
import { ReactNode } from 'react';

const getPrimaryName = (names: RegionalNameInformation): string | undefined =>
  'globalNames' in names
    ? names.globalNames?.all.displayName
    : names.profiledNames?.allExceptBjs.displayName;

// This is still bad and needs refactoring later, but it should fix the build for now
export const makeOfferingOrDraftCell = (
  serv: Offering | OfferingReview,
  prop: keyof (Offering & OfferingReview)
): ReactNode => {
  if (!(prop in serv)) return '';
  const value = serv[prop as keyof typeof serv];
  console.log('value' + JSON.stringify(value) + typeof value);
  switch (typeof value) {
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'undefined':
      return '';
    default:
      switch (prop) {
        case 'offeringStatus':
          return (
            value && (
              <OfferingStatusIndicator status={value as OfferingStatus} />
            )
          );
        case 'offeringReviewStatus':
          return (
            <OfferingReviewStatusIndicator
              status={value as OfferingReviewStatus}
            />
          );
        case 'extraLongName':
        case 'extraShortName':
        case 'shortName':
        case 'longName':
          return value ? getPrimaryName(value as RegionalNameInformation) : '';
        case 'localizationWords':
          return (value as LocalizationWords)?.words.join(' ');
        case 'docsCategories':
          if (Array.isArray(value)) {
            const categoryNames = (value as DocumentationCategory[])
              .filter(
                (item): item is DocumentationCategory =>
                  typeof item === 'object' && 'categoryName' in item
              )
              .map((item: DocumentationCategory) => item.categoryName);

            return categoryNames;
          }
          return [];

        case 'offeringName':
          return (
            <Link
              href={`#/Moniker/${
                'offeringReviewId' in serv
                  ? `drafts/${serv.offeringReviewId}`
                  : `offerings/${encodeURIComponent(serv.offeringId)}`
              }`}
            >
              {serv['offeringName']}
            </Link>
          );
        default:
          return String(value);
      }
  }
};
